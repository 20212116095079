// Here you can add other styles

html:not([dir="rtl"]) .card-header-actions {
  flex: 1;
  text-align: right;
}

html:not([dir="rtl"]) .card-header-actions-added {
  flex: 0.9;
  text-align: right;
  align-self: center;
}

html:not([dir="rtl"]) .card-added-rewards {
  flex: 0.9;
  align-self: flex-end;
  margin-bottom: 20px;
  margin-top: 20px;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  height: 35px;
}
html:not([dir="rtl"]) .card-added-rewards-right {
  margin-left: 15px;
}

html:not([dir="rtl"]) .deleteButton {
  margin-left: 15px;
  align-self: center;
  // justify-content: flex-end;
  // flex: 1;
  // margin-bottom: 20px;
  // margin-top: 20px;
  align-items: flex-end;
  position: absolute;
  right: 0;
  margin-right: 30px;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  background-color: #232323;
  color: #ffffff;
  font-family: AvenirNext-Regular;
  border-color: #232323;
}

html:not([dir="rtl"]) .title-banner-update {
  flex: 0.9;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 20px;
}
.red-alert-list-pencapaian {
  color: #af0000;
}

html:not([dir="rtl"]) .title-text-banner {
  font-family: AvenirNext-Regular;
  color: #212121;
  font-size: 16px;
  font-weight: bold;
}

html:not([dir="rtl"]) .title-banner-year {
  width: 100%;
}

html:not([dir="rtl"]) .title-banner-number {
  min-width: 30px;
  max-width: 80px;
}

html:not([dir="rtl"]) .title-banner-achieve {
  width: 400%;
  flex: 1;
}

.flex-banner-update {
  flex-direction: row;
  width: 100%;
  display: flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  font-family: AvenirNext-Regular;
  color: #212121;
  font-size: 15px;
}

.c-app {
  color: #212121;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-control:focus {
  color: var(--color-primary);
  background-color: transparent;
  border-color: var(--color-primary);
  /* outline: 0; */
  box-shadow: none;
}

.btn-dark {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.btn {
  padding-top: 12px;
  padding-bottom: 12px;
}

.card-header {
  align-items: center;
  justify-content: center;
  display: flex;
}

.c-sidebar-minimized .logo {
  width: 0;
  transition:
    width 0s,
    height 0s;
}
.logo {
  width: 200px;
  transition:
    width 1s,
    height 1s;
}
.m-center {
  margin: 0 auto;
}
.doorprize {
  width: 220px;
  transition:
    width 1s,
    height 1s;
}
.action-button {
  padding-left: 5px;
  padding-right: 5px;
}
.action-icon {
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.color-action {
  color: var(--color-action) !important;
}
.color-red {
  color: var(--color-red) !important;
}
.color-secondary {
  color: var(--color-secondary) !important;
}
.align-center {
  display: flex;
  align-items: center;
}
.justify-center {
  display: flex;
  justify-content: "center";
}
.block {
  display: block;
}
.errorstyle {
  margin-top: -10px;
  margin-bottom: 10px;
}

.active-input input {
  border: none;
  border-bottom: 1px solid var(--color-primary);
  padding-bottom: 30px;
  text-align: center;
  border-radius: 0em;
  font-size: var(--big-font);
  color: var(--color-primary);
  font-family: AvenirNext-Bold;
}

.active-text {
  font-family: AvenirNext-Demi;
  color: var(--color-secondary);
  text-decoration: underline;
}

.disabled-action {
  cursor: default;
}

.h-400 {
  min-height: 400px;
}

.middle {
  align-items: center;
  display: flex;
  justify-content: center;
}

.table,
.form-control,
.custom-select {
  color: #212121;
}

.badge-warning {
  color: #ffffff;
}
.phone-input {
  max-width: 80px;
}
h4,
.h4 {
  font-family: AvenirNext-Demi;
}
h2 {
  font-family: AvenirNext-Bold;
}
h6 {
  margin-bottom: 0;
}
.flex {
  display: flex;
  flex: 1;
}
.flex-auto {
  flex: inherit !important;
}
.title {
  font-size: 15px;
  font-family: AvenirNext-Demi;
  color: #212121;
}
.row {
  flex-direction: row;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.c-sidebar {
  background-color: var(--color-primary);
}
.page-item.active .page-link {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.page-link,
a {
  color: var(--color-primary);
}
.c-thumbnail-full {
  width: 55px;
  object-fit: cover;
}
.c-thumbnail {
  width: 42px;
  height: 42px;
  object-fit: cover;
}
.c-thumbnail-sm {
  width: 25px;
  height: 25px;
  object-fit: cover;
}
.c-thumbnail-sm2 {
  width: 22px;
  height: 22px;
  object-fit: cover;
}
.ico-password {
  position: absolute;
  right: 20px;
  top: -4px;
}
.cover {
  width: 100%;
}
.disable {
  opacity: 0.5;
  cursor: default;
}
.c-vote {
  min-height: 70vh;
  display: flex;
  align-items: center;
}
.c-upload-image {
  margin-top: 10px;
  max-width: 142px;
  height: auto;
}
.upload-hasValue {
  color: transparent;
}
.form-input span {
  color: var(--color-red);
}
.infinite-scroll {
  height: 400px;
  overflow: auto;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-row {
  flex-direction: row;
}
.flex {
  flex: 1;
}
.no-padd {
  padding-left: 0em;
  padding-right: 0em;
}
.ql-formats span {
  color: #212121;
}
.c-switch-slider {
  border: 1px solid #321fdb;
}
.c-switch-slider::before {
  border: 1px solid #321fdb;
}
.tox-notification {
  display: none !important;
}
.c-sidebar-nav-dropdown img,
.c-sidebar-nav-item img {
  margin-right: 20px;
  max-width: 21px;
}
.action-link a {
  color: #5497f1 !important;
  font-family: AvenirNext-Demi;
  text-decoration: underline;
}
.note-detail-pesanan {
  background-color: #ebebeb;
  padding: 17px;
  border-radius: 10px;
  margin-top: 20px;
  color: #262626;
}
.note-detail-pesanan p {
  color: #212121;
  font-family: AvenirNext-Demi;
}
.note-detail-pesanan span {
  color: #212121;
  font-family: AvenirNext-Demi;
}
.note-detail {
  background-color: #ebebeb;
  padding: 17px;
  border-radius: 10px;
  margin-top: 20px;
  max-width: 500px;
  color: #262626;
}
.note-detail p {
  color: #212121;
  font-family: AvenirNext-Demi;
  margin-bottom: 10px;
}

.note-detail2 {
  background-color: #ebebeb;
  padding: 17px;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 10px;
  color: #262626;
}
.note-detail2 p {
  color: #212121;
  font-family: AvenirNext-Demi;
  margin-bottom: 10px;
}

@media only screen and (max-width: 480px) {
  .card-body {
    padding-left: 0em;
    padding-right: 0em;
  }
}

@media only screen and (max-width: 360px) {
  .text-otp {
    display: block;
  }
  .mb-5,
  .my-5 {
    margin-bottom: 2rem !important;
  }
}

//selectt
.select-search {
  position: relative;
  font-family: AvenirNext-Regular;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid #d8dbe0;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: AvenirNext-Regular;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
  padding: 0;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: AvenirNext-Regular;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #212121;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #212121;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #212121;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
  border: 1px solid #212121;
}

.select-search__select ul {
  margin-bottom: 0;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.green {
  color: #1db359;
}

.red {
  color: #ff0000;
}

.statusPengiriman .btn-secondary:active {
  background-color: #beaf87;
  border-color: #beaf87;
  color: #fff;
}
.statusPengiriman .btn-secondary {
  background-color: transparent;
  margin-right: 15px;
  font-size: 17px;
  font-family: AvenirNext-Demi;
  padding: 10px;
  min-width: 200px;
  border-radius: 10px !important;
  border-color: #212121;
  border-width: 2px;
  color: #212212;
}
.statusPengiriman .btn-secondary.activeStatus {
  background-color: #beaf87;
  border-color: #beaf87;
  color: #fff;
}

.status_order h5 {
  color: #212121;
  font-family: AvenirNext-Demi;
  margin-bottom: 10px;
  font-size: 16px;
}

.status .col {
  flex-direction: column;
}

.status .row {
  align-items: flex-start;
}
.btn:focus,
.btn.focus {
  box-shadow: none;
}

.wrap {
  flex-wrap: wrap !important;
}

.resi .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.resi .modal-body {
  padding: 0 40px 40px 40px;
}
.resi .modal-body p {
  text-align: center;
  font-family: AvenirNext-Demi;
  color: #212121;
}

.centered {
  text-align: center;
}

#wheel circle {
  fill: #212121;
}

#wheel g:not(:last-child) text {
  fill: #fff;
  font-family: AvenirNext-Demi;
  writing-mode: tb-rl;
  transform: translateY(-50px);
  font-size: 12px;
}

#wheel g:last-child text[fill="white"] {
  fill: #fff;
  font-family: AvenirNext-Demi;
  transform: matrix(1, 0, 0, 1.02, -19.915, 0);
}

.modal-lucky .title {
  font-family: "AvenirNext-Demi";
  font-size: 20px;
  margin-top: 20px;
}

.modal-lucky .description {
  font-family: "AvenirNext-Demi";
  font-size: 22px;
  margin-bottom: 20px;
  color: var(--color-secondary);
}

.modal-lucky-center .title {
  font-family: "AvenirNext-Demi";
  font-size: 20px;
  margin-top: 0.5rem;
}

.bg-lucky {
  display: flex;
  padding: 30px 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(190, 175, 135, 1) 100%);
  height: 100vh;
}

.title-lucky {
  font-family: "AvenirNext-Bold";
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  text-shadow: 0px 3px 6px #00000029;
}

.title-version-lucky {
  font-family: "AvenirNext-Bold";
  font-size: 10px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  text-shadow: 0px 3px 6px #00000029;
}

.img-lucky {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;
  background-color: #beaf87;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
  img {
    height: 200px;
  }
}

.button-lucky {
  box-shadow: 0px 3px 6px #00000029;
  background: #242426 0% 0% no-repeat padding-box;
  font-family: "AvenirNext-Bold";
  border-radius: 10px;
  width: 186px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #fff;
}

#wheel g:not(:last-child) text {
  font-size: 14px !important;
}

.mt-48 {
  margin-top: 48px;
}

.mt-24 {
  margin-top: 24px;
}

.mr-16 {
  margin-right: 16px;
}
.mr-32 {
  margin-right: 32px;
}
.mr-56 {
  margin-right: 56px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-40 {
  margin-bottom: 40px;
}
.mb-16 {
  margin-bottom: 16px;
}

.box-flex {
  display: flex;
}

.justify-end {
  justify-content: flex-end;
}

.visibility-hidden {
  visibility: hidden;
}

.w-71 {
  width: 71px;
}

.self-end {
  justify-self: end;
}

.btn-white-border {
  border: 1px solid #d8d8d9;
  background: #fff;
}

.btn-white {
  border: 1px solid #d8d8d9;
  background: #fff;
  color: #252526;
}

.btn-green {
  border: 1px solid #02ac5b;
  background: #02ac5b;
  color: #fff;
  border-radius: 4px;
}

.btn-orange {
  border: 1px solid #F6E5B6;
  background: #F6E5B6;
  color: #212121;
  border-radius: 4px;
}

.btn-red {
  border: 1px solid #fd4949;
  background: #fff;
  color: #fd4949;

  &:disabled {
    opacity: 0.5;
  }
}

.cursor-pointer{
  cursor: pointer;
}

.custom-modal-loader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}
