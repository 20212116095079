@font-face {
  font-family: "AvenirNext-Regular";
  src: url(../assets/fonts/AvenirNextLTPro-Regular.otf) format("opentype");
}

@font-face {
  font-family: "AvenirNext-Demi";
  src: url(../assets/fonts/AvenirNextLTPro-Demi.otf) format("opentype");
}

@font-face {
  font-family: "AvenirNext-Bold";
  src: url(../assets/fonts/AvenirNextLTPro-Bold.otf) format("opentype");
}

@font-face {
  font-family: "AvenirNext-Heavy";
  src: url(../assets/fonts/AvenirNextLTPro-Heavy.otf) format("opentype");
}

@font-face {
  font-family: "Gotham-Medium";
  src: url(../assets/fonts/GothamMedium.ttf) format("truetype");
}

@font-face {
  font-family: "Manrope";
  src: url(../assets/fonts/Manrope/static/Manrope-Regular.ttf) format("truetype");
}
