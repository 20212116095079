// Variable overrides
:root {
  --color-primary: #242426;
  --color-secondary: #beaf87;
  --color-action: #321fdb;
  --color-red: #e55353;
  --base-font: 14px;
  --big-font: 18px;
}

$theme-colors: (
  "white":       #fff
);